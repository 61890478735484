<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title
          >Nombre d'établissements touristiques par type
        </b-card-title>
      </b-card-header>

      <b-card-body class="d-flex flex-column justify-content-center">
        <content-loader
          v-if="isLoading && false"
          view-box="0 0 400 460"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <circle cx="29" cy="30" r="17" />
          <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
          <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
          <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
        </content-loader>
        <div
          v-if="isLoading"
          class="d-flex align-items-center justify-content-center"
        >
          <b-spinner label="Spinning" />
        </div>
        <b-row>
          <b-col lg="12">
            <!-- apex chart -->
            <vue-apex-charts
              v-if="!isLoading"
              type="pie"
              height="325"
              class="mt-2 mb-1"
              :options="typesEtablissementPieChart.chartOptions"
              :series="typesEtablissementPieChart.series"
            />
          </b-col>
          <b-col v-if="false" lg="12">
            <!-- list group -->
            <div v-if="!isLoading" class="pt-25">
              <div
                v-for="(data, index) in chartData.listData"
                :key="index"
                class="d-flex justify-content-between"
                :class="
                  index === Object.keys(chartData.listData).length - 1
                    ? ''
                    : 'mb-1'
                "
              >
                <div class="series-info">
                  <!-- <feather-icon
                    :icon="data.icon"
                    size="16"
                    class="mr-50"
                    :class="data.iconColor"
                  /> -->

                  <span class="font-weight-bolder">{{ data.text }}</span> &nbsp;
                  <b-badge variant="light-dark">
                    <span>{{ data.result }}</span>
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";

// store modules and vuex utilities
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const chartLabels = [];
const chartData = {
  lastDays: ["28 Derniers jours", "Mois dernier", "Annee Dernière"],
  listData: [
    // {
    //   icon: 'CircleIcon',
    //   iconColor: 'text-primary',
    //   text: 'Transport par eau',
    //   result: 0,
    // },
    // {
    //   icon: 'CircleIcon',
    //   iconColor: 'text-warning',
    //   text: 'Transport par route',
    //   result: 0,
    // },
    // {
    //   icon: 'CircleIcon',
    //   iconColor: 'text-danger',
    //   text: 'Transport par air',
    //   result: 0,
    // },
    // {
    //   icon: 'CircleIcon',
    //   iconColor: 'text-danger',
    //   text: 'Transport par train',
    //   result: 0,
    // },
  ],
};
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    ContentLoader,
    BSpinner,
    BBadge,
  },
  props: {
    statsData: {
      type: Object,
      default: () => ({
        par_eau_: 0,
        par_route: 0,
        par_avion: 0,
        series: [0, 0, 0],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      chartLabels,
      chartData,
      typesEtablissementPieChart: {
        series: [0, 0, 0],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
      // isLoading: true,
      statisticsRequestData: null,
    };
  },
  computed: {
    totalsValues() {
      if (!this.statisticsRequestData || !this.statisticsRequestData.total) {
        return null;
      }
      return this.statisticsRequestData.total;
    },
  },
  watch: {
    statsData: {
      deep: true,
      handler(val, old) {
        const { series, labels } = val || {
          series: [0, 0, 0],
          labels: ["", "", ""],
        };
        this.typesEtablissementPieChart = {
          ...this.typesEtablissementPieChart,
          series,
          chartOptions: { ...this.typesEtablissementPieChart, labels },
        };
        // this.chartLabels = labels
        this.chartData.listData = labels.map((e, index) => {
          const temp = {
            text: `${labels[index]}`,
            result: series[index] || 0,
          };
          // console.log('e :>> ', e)

          return temp;
        });
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  async mounted() {
    // this.isLoading = false
    // await this.getStatistics()
  },
  created() {},
  methods: {
    processEtsCount(resources) {
      const { resourcesCount, valuesObjects } = resources || {};
      let result = [];
      if (
        Array.isArray(resourcesCount) &&
        Array.isArray(valuesObjects) &&
        resourcesCount.length === valuesObjects.length
      ) {
        // eslint-disable-next-line comma-spacing
        result = valuesObjects.map((e, i) => ({ [e.nom]: resourcesCount[i] }));
      }
      return result;
    },
  },
};
</script>
